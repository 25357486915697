<div class="Header">
    <button class="audience-button"
        mat-flat-button type="button"
        title="Show audience"
        aria-label="Show audience"
        (click)="onShowAudience()"><mat-icon class="tab-icon">group</mat-icon>
        Audience
    </button>

    <div class="title">
        <img src="/assets/img/comments.svg">{{title}}
        <elevate-tooltip [size]="16" [tipData]="tooltips['Discussion']" style="margin-left: 4px; --text-subdued-color: #cccccc"></elevate-tooltip>
    </div>
    <div class="subtitle">{{subtitle}}</div>
</div>

<div #notesControl class="NotesControl" [class.readonly]="asset?.access < 2"></div>

<ejs-dialog #imageSelector id="imageSelector"
    (overlayClick)="imageSelector.hide()"
    [header]="'Insert Image'"
    allowDragging='true' enableResize='true'
    [isModal]='true'
    [visible]="false"
    [resizeHandles]="['All']"
    [showCloseIcon]='true'
    [cssClass]="'imageSelector'">
    <ng-template #content>
        <ejs-filemanager #fileManager
            [path]="path"
            [rootAliasName]="accountName"
            [ajaxSettings]="fileManagerSettings.ajaxSettings"
            [uploadSettings]="fileManagerSettings.uploadSettings"
            [allowMultiSelection]='false'
            (fileSelect)="onFileSelect($event, fileManager)"
            >
            <e-toolbaritems>
                <e-toolbaritem name="Upload"></e-toolbaritem>
                <e-toolbaritem name="SortBy"></e-toolbaritem>
                <e-toolbaritem name="Refresh"></e-toolbaritem>
                <e-toolbaritem name="View"></e-toolbaritem>
            </e-toolbaritems>
        </ejs-filemanager>
    </ng-template>
</ejs-dialog>

<ejs-dialog #linkSelector id="linkSelector"
    (overlayClick)="linkSelector.hide()"
    header="Insert Link"
    [visible]='false'
    [isModal]='true'
    [target]='targetElement'
    [buttons]="linkSelectButtons"
    showCloseIcon="true"
    [cssClass]="'linkSelector'">
    <ng-template #content>
        <div class="dialogContent">
            <div>Web address</div>
            <input type="text" placeholder="https://example.com" name="address">
            <div>Display text</div>
            <input type="text" placeholder="Enter text" name="title">
        </div>
    </ng-template>
</ejs-dialog>
