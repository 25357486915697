import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Host, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import DOMPurify from 'dompurify';
import { AudienceComponent } from 'src/app/pages/@dialogs/event-properties/audience/audience.component';
import { ElevateTipComponent, TooltipEntries } from 'src/app/pages/general/elevate-tooltip/elevate-tooltip.component';
import { getPersonaLabel, getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { DialogOptions } from 'src/app/services/dialog.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { UserProfile } from 'src/app/types/user';
import { FormatDate } from 'src/app/utils/pipes/datetime.pipe';
import { DTO, Note, NotesAPI, PartialNote } from 'src/dto/dto';
import { tooltips } from './tooltips';
import { Picker } from 'emoji-picker-element';
import { UserService } from 'src/app/services/user.service';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import {
    DetailsViewService,
    ToolbarService,
    FileManagerComponent,
    FileManagerModule,
    FileSelectEventArgs,
    NavigationPaneService
} from '@syncfusion/ej2-angular-filemanager';
import { MatButtonModule } from '@angular/material/button';
import { FileManagerSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    providers: [
        HttpClientModule,
        ToolbarService, NavigationPaneService, DetailsViewService
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        ElevateTipComponent,
        MatButtonModule,
        DialogModule,
        FileManagerModule
    ]
})
export class NotesComponent implements AfterViewInit, OnDestroy {
    readonly tooltips: TooltipEntries = tooltips;

    @ViewChild('linkSelector')
    public linkSelector?: DialogComponent;
    @ViewChild('imageSelector')
    public imageSelector?: DialogComponent;
    @ViewChild('fileManager')
    public fileManager: FileManagerComponent;
    @ViewChild("notesControl")
    public notesContainer: ElementRef;

    public title = "Comments";
    public subtitle = "";

    private notesControl: NotesControl;

    // Initial navigation of the file manager
    public path = "/";

    private _asset: DTO = null;
    @Input() set asset(value: DTO) {
        this._asset = value;
        this.initialize();

        // Set the initial folder for the file manager to the one 'owned' by this asset.
        const isEvent = typeof this._asset['eventType'] == "number";
        if (isEvent) {
            const scope = `Event_v1.${this._asset.id}`;
            this.path = `/elevate_files/EDTEVT_Files/${scope}`;
        }
        else {
            const scope = `${this._asset.dto ?? 'Unknown'}.${this._asset.id}`;
            this.path = `/elevate_files/${this._asset.edtCode ?? 'Unknown'}_Files/${scope}`;
        }
    }
    get asset() {
        return this._asset || { id: -1, name: "(unknown name)", dto: "UNKNOWN"}
    }

    private readonly tenantName: string  = this.tenant.value.es_tenant_name;
    public  readonly accountName: string = this.tenant.value.account_name;

    constructor(
        public readonly theme: ThemeLoaderService,
        private readonly tenant: TenantService,
        public dialog: MatDialog
    ) {
    }

    public fileManagerSettings: FileManagerSettingsModel = {
        enable: true,
        path: '/',
        ajaxSettings: {
            url:         `/api/sfa/v2.0/FileOperations?es-tenant=${this.tenantName}`,
            getImageUrl: `/api/sfa/v2.0/GetImage?es-tenant=${this.tenantName}`,
            uploadUrl:   `/api/sfa/v2.0/Upload?es-tenant=${this.tenantName}`,
            downloadUrl: `/api/sfa/v2.0/Download?es-tenant=${this.tenantName}`
        },
        uploadSettings: {
            autoUpload: true,
            minFileSize: 0,
            maxFileSize: 30000000,
            allowedExtensions: '',
            autoClose: false
        }
    };

    public linkSelectButtons: Object = [
        {
          'click': (args) => this.onLinkSelect(args),
           // Accessing button component properties by buttonModel property
            buttonModel:{
            content: 'Insert',
            // Enables the primary button
            isPrimary: true
          }
        },
        {
          'click': (event) => this.linkSelector.hide(event),
          buttonModel: {
            content: 'Cancel'
          }
        }
      ];


    public targetElement?: HTMLElement;

    ngAfterViewInit() {
     //   this.targetElement = this.notesContainer.nativeElement;
        this.initialize();
    }

    ngOnDestroy() {
        this.notesControl?.dispose();
    }

    private emojiPicker = null;

    public openEmojiSelector(controlBar: HTMLDivElement) {
        if (!this.emojiPicker) {
            this.emojiPicker = new Picker();
            controlBar.appendChild(this.emojiPicker);
            this.emojiPicker.addEventListener('emoji-click', event => {
                const uniChar = event.detail.unicode;
                const hexCode = `&#x${uniChar.codePointAt(0).toString(16)}`;
                this.notesControl.insertReference(hexCode);

                this.emojiPicker.style.display = "none";
                setTimeout(() => { this.emojiPicker.style.display = ""; }, 100);
            });
        }

    }

    public openLinkSelector(editorObject: HTMLDivElement) {
        const selector = this.linkSelector;
        if (!selector) return;

        const editorCoords = editorObject.getBoundingClientRect();

        const relativeX = editorCoords.left;
        const relativeY = editorCoords.bottom;

        selector.width  = 300;
        selector.height = 270;
        selector.position.X = relativeX - selector.width;
        selector.position.Y = relativeY - selector.height - 36;

        selector.show();
    }

    public openImageSelector(editorObject: HTMLDivElement) {
        const selector = this.imageSelector;
        if (!selector) return;

        const editorCoords = editorObject.getBoundingClientRect();

        const relativeX = editorCoords.left;
        const relativeY = editorCoords.bottom;

        selector.width  = 500;
        selector.height = 400;
        selector.position.X = relativeX - selector.width;
        selector.position.Y = relativeY - selector.height - 36;

        selector.show();
    }

    public onFileSelect(args: FileSelectEventArgs | any, fileManager: FileManagerComponent) {
        if (args.action != "select" ||
            args.name != "fileSelect" ||
            !args.fileDetails.isFile)
            return;

        const supportedExtensions = ["png", "svg", "jpg", "jpeg", "gif"];
        const extension = args.fileDetails.type.startsWith(".")
                        ? args.fileDetails.type.substring(1)
                        : args.fileDetails.type;

        if (!supportedExtensions.includes(extension)) return;

        const fileUri = args.fileDetails.location;
        fileManager.clearSelection();

        // This was a good selection of an image. We can insert this into the editor.
        const image = '/api/efa/v2.0/file/' + fileUri;
        const link =`![${image.split('/').pop().split('.')[0]}](${image}?es-tenant=${this.tenantName})`;

        this.notesControl.insertReference(link);

        this.imageSelector.hide();
    }

    public onLinkSelect(args) {
        const selector = this.linkSelector;
        if (!selector) return;

        const form = selector.element;
        let address: HTMLInputElement = form.querySelector('input[name="address"]') as HTMLInputElement;
        let title: HTMLInputElement = form.querySelector('input[name="title"]') as HTMLInputElement;

        const link = `[${title.value || "here"}](${address.value || "https://elevate.dynatrace.com"})`;
        this.notesControl.insertReference(link);

        this.linkSelector.hide();
    }

    public onEmojiSelect(args) {
        // TODO
    }

    onShowAudience() {
        const options : DialogOptions = { isResizable: true, isModal: true };

        this.dialog.open(AudienceComponent, {
            height: "60%",
            width: "60%",
            panelClass: [
                "dialog-audience", // Where does this come from?
                options.isResizable ? 'dialog-resizable' : '',
                options.isModal ? 'dialog-draggable' : '',
                options.isDark ? 'dialog-dark' : 'dialog-light',
                ...(Array.isArray(options.panelClass) ? options.panelClass : [options.panelClass])
            ],
            ...options,
            closeOnNavigation: true,
            restoreFocus: true,
            data: this.asset
        });
    }

    private initialize() {
        if (!this.asset || !this.notesContainer) return;

        let isFirstUpdate = true;

        this.notesControl?.dispose();
        this.notesControl = new NotesControl(
            window.tenant,
            this.notesContainer.nativeElement,
            DTO.getEndpointAPI(this.asset.dto).notesAPI,
            this.asset,
            window.root.user,
            notes => {
                if (notes?.length > 0) {
                    const lastUpdate    = notes[notes.length - 1];
                    const lastUpdatedBy = lastUpdate?.name || lastUpdate?.authorName || lastUpdate?.authorEmail || "unknown user";
                    const lastUpdatedOn = lastUpdate?.enteredDate;

                    this.title = notes.length + " Comments";
                    const user  = this.tenant.users.value.find(u => u.email?.trim().toLowerCase() == lastUpdatedBy);
                    const recentCount = this.asset.kpiData?.kpi_notes?.recentCount || 0;
                    this.subtitle = `${recentCount} new comments in last 7 days. Last comment added by ${user?.name || lastUpdatedBy} on ${FormatDate(lastUpdatedOn)}.`;
                }
                else {
                    this.subtitle = `Be the first to comment.`;
                }
                if (isFirstUpdate) {
                    isFirstUpdate = false;
                    this.notesControl.scrollToEnd();
                }
            },
            this.openImageSelector.bind(this),
            this.openLinkSelector.bind(this),
            //this.openEmojiSelector.bind(this)
        );
    }
}

class SendButton {
    public button = null;

    private sendPart = null;
    private dynatraceOption = null;
    private publicOption = null;
    private visibilityControl = null;

    constructor(
        private readonly noteEditor,
        clickHandler
    ) {
        // Let's start with the "SEND" button.
        this.button = document.createElement("div");
        this.button.className = "send-button";

        this.sendPart = document.createElement("div");
        this.sendPart.className = "send-part";
        this.sendPart.innerHTML = '<span><img src="/assets/img/send.svg"></span>';
        this.sendPart.addEventListener("mousedown", clickHandler, true);

        this.visibilityControl = document.createElement("div");
        this.visibilityControl.className = "icon-part";
        this.visibilityControl.innerHTML = '<img src="/assets/img/down-chevron.svg">';

        const visibilityPanel = document.createElement("div");
        visibilityPanel.className = "audience-panel";

        this.dynatraceOption = document.createElement("div");
        this.dynatraceOption.innerHTML = "Dynatrace only";
        this.publicOption = document.createElement("div");
        this.publicOption.innerHTML = "To everybody";

        this.dynatraceOption.addEventListener("click", () => this.setVisibility("1"));
        this.publicOption.addEventListener("click", () => this.setVisibility("0"));

        visibilityPanel.appendChild(this.dynatraceOption);
        visibilityPanel.appendChild(this.publicOption);

        this.visibilityControl.appendChild(visibilityPanel);

        // Now we set the initial visibility.
        this.setVisibility(noteEditor.dataset['notetype']);

        this.button.appendChild(this.sendPart);
        this.button.appendChild(this.visibilityControl);
    }

    setVisibility(visibility: string = "0", readonly = false) {
        this.noteEditor.dataset['notetype'] = visibility;
        this.button.dataset['notetype'] = visibility;

        const innerSpan = this.sendPart.firstElementChild as HTMLSpanElement;

        if (visibility == "1") {
            if (readonly)
                this.button.classList.add("readonly");
            else
                this.button.classList.remove("readonly");

            innerSpan.dataset['visibility'] = "Dynatrace only";
            this.dynatraceOption.classList.add("selected");
            this.publicOption.classList.remove("selected");
        }
        else {
            this.button.classList.remove("readonly");

            innerSpan.dataset['visibility'] = "To everybody";
            this.dynatraceOption.classList.remove("selected");
            this.publicOption.classList.add("selected");
        }

    }
}

class NotesControl {
    notesArea: HTMLElement;
    notesAPI: NotesAPI;
    access: number;
    imageSelect: HTMLDivElement;
    linkSelect: HTMLDivElement;
    emojiSelect: HTMLDivElement;
    visibilitySelect: HTMLSelectElement;
    assetId: number;
    user: UserService;
    email: string;
    notesList: HTMLDivElement;
    noteEditor: HTMLDivElement;
    textArea: any;
    sendButton: SendButton;
    currentNote: any;
    notes: Note[];
    onChange: Function;

    autoRefresh = null;
    refreshInterval = 30; // Seconds.

    constructor(
        private readonly tenant: TenantService,
        notesArea: HTMLElement,
        notesAPI: NotesAPI,
        asset: DTO,
        user: UserService,
        onChange?: Function,
        onOpenImageSelector?: Function,
        onOpenLinkSelector?: Function,
        onOpenEmojiSelector?: Function
    ) {
        if (!notesArea || !asset) return;

        this.notesArea = notesArea;
        this.notesAPI  = notesAPI;
        this.access    = asset.access;
        this.assetId   = asset.id;
        this.user      = user;
        this.email     = user.value?.email?.toLowerCase() || "(no active user)";
        this.onChange  = (notes) => {
            this.startAutoRefresh();
            if (onChange) onChange(notes);
        };

        this.notesArea.innerHTML = "";

        this.notesList = document.createElement("div");
        this.notesList.className = "Scrollbar NotesList";
        this.notesList.style.overflowY = "auto";
        this.notesList.innerHTML = "<p style='padding-left: 18px'>Loading...</p>";

        this.notesArea.appendChild(this.notesList);

        this.noteEditor = document.createElement("div");
        this.noteEditor.id = "noteEditor";
        this.noteEditor.className = "Scrollbar NoteEditor";
        this.noteEditor.style.display = "flex";
        this.noteEditor.dataset['notetype'] = "0";
        this.noteEditor.dataset['mode'] = "New";
        this.noteEditor.dataset['note'] = null;
        this.noteEditor.dataset['parent'] = null;

        const controlArea = document.createElement("div");
        controlArea.className = "control-area";

        // We want 'image', 'emoji', 'link', 'at' on the left.
        // We want 'x' and 'SEND + TO' on the right.
        if (onOpenImageSelector) {
            this.imageSelect = document.createElement("div");
            this.imageSelect.className = "image-select";
            this.imageSelect.title = "Insert an image";
            this.imageSelect.innerHTML = '<span class="e-btn-icon e-image e-icons">';
            this.imageSelect.addEventListener("click", event => onOpenImageSelector(this.noteEditor));
            controlArea.appendChild(this.imageSelect);
        }
        if (onOpenLinkSelector) {
            this.linkSelect = document.createElement("div");
            this.linkSelect.className = "image-select";
            this.linkSelect.title = "Insert a link";
            this.linkSelect.innerHTML = '<span class="e-btn-icon e-create-link e-icons">';
            this.linkSelect.addEventListener("click", event => onOpenLinkSelector(this.noteEditor));
            controlArea.appendChild(this.linkSelect);
        }
        if (onOpenEmojiSelector) {
            this.emojiSelect = document.createElement("div");
            this.emojiSelect.className = "image-select emoji";
            this.emojiSelect.innerHTML = '<span class="e-btn-icon e-smiley e-icons">';
            this.emojiSelect.addEventListener("click", event => onOpenEmojiSelector(controlArea));
            controlArea.appendChild(this.emojiSelect);
        }

        this.sendButton = new SendButton(
            this.noteEditor,
            event => this.finishEdit({ currentTarget: this.textArea })
        );
        controlArea.appendChild(this.sendButton.button);

        this.textArea = document.createElement("textarea");
        this.textArea.className = "Scrollbar NewNote";
        this.textArea.placeholder = "Add a comment";
        this.textArea.value = "";
        this.textArea.addEventListener("keypress", event => {
            if (event.keyCode == 13 && !event.shiftKey) {
                event.preventDefault(); // Prevents 'Enter' from creating a new line.
                this.finishEdit(event);
                this.textArea.value = "";
            }
            if (event.keyCode == 27) {
                event.preventDefault(); // Prevents 'Esc' from closing the dialog.
                this.cancelEdit(event);
            }
            return true;
        });

        this.noteEditor.appendChild(this.textArea);

        const cancelButton = document.createElement("span");
        cancelButton.className = "icon icon-close";
        cancelButton.title = "Cancel (Esc)";
        cancelButton.style.backgroundSize = "16px 16px";
        cancelButton.innerHTML = "&nbsp;";
        cancelButton.addEventListener("mousedown", event => this.cancelEdit({ currentTarget: this.textArea }), true);

        this.noteEditor.appendChild(cancelButton);

        this.noteEditor.appendChild(controlArea);
        this.notesArea.appendChild(this.noteEditor);

        // If you don't have better access than LIMITED, you can't add
        // comments or reply to comments.
        this.noteEditor.style.display = this.access > 2 ? "" : "none !important";
        this.startAutoRefresh(true);
    }

    public scrollToEnd() {
        this.notesList.scrollTo(0, this.notesList.scrollHeight);
    }

    // CAUTION: Keep current scroll position. If the height
    // has changed, we can show something at the bottom to
    // inform the user there are new notes.
    // DONT refresh while the user is replying to something.
    stopAutoRefresh(forever = false) {
        clearInterval(this.autoRefresh);
        this.autoRefresh = forever ? null : -1;
    }
    startAutoRefresh(immediately = false) {
        // If we we paused, retrieve data now and set up a
        // new autoRefresh. Else, do nothing.
        if (this.autoRefresh == -1 || immediately) {
            this.renderAllNotes();
            this.autoRefresh = setInterval(() =>
                this.renderAllNotes(),
                this.refreshInterval * 1000);
        }
    }

    dispose() {
        this.stopAutoRefresh(true); // Forever.
    }

    insertReference(link) {
        this.textArea.setRangeText(link, this.textArea.selectionStart, this.textArea.selectionEnd, "select");
    }

    private hasChanges(notes: Note[]): boolean {
        if (!this.notes?.length ||
             this.notes?.length != notes.length) return true;

        // There are changes if it's NOT true that we can find every note
        // in the previous dataset with the same enteredDate and description.
        return !notes.every(note => {
            const  orgNote = this.notes.find(orgNote => orgNote.id == note.id);
            return orgNote &&
                   orgNote.enteredDate == note.enteredDate &&
                   orgNote.description == note.description;
        });
    }

    async renderAllNotes() {
        if (!this.assetId) return;

        // Preserve current scroll position.
        const scrollTop = this.notesList.scrollTop;

        try {
            if (this.user.sessionExpired) {
                this.stopAutoRefresh(true);
                return;
            }

            let notes = await this.notesAPI.select(this.assetId);
            notes = notes
                .map(note => {
                    note.enteredDate = new Date(note.enteredDate);
                    note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";
                    note.hasReplies  = !!notes.find(n => note.parentId == n.id);
                    return note;
                })
                .sort((a, b) =>
                    a.enteredDate.getTime() - b.enteredDate.getTime()
                );

            if (!this.hasChanges(notes)) return;
            // console.log("Found new notes!");
            this.notes = [...notes];

            // Keep appending notes, ordered by date. If there is a parent,
            // find the parent and append it there. If it doesn't exist yet,
            // wait until it does exist. Once the list stops shrinking,
            // append the rest to the end.
            this.notesList.innerHTML = "";

            let length = 0;
            while (length !== notes.length) {
                // Check if we're still shrinking (i.e. rendering notes).
                // We're really defensive here because we ordered by time,
                // so parents will always exist when a child (reply) is
                // rendered. Theoretically, at least...
                length = notes.length;

                for (let i = 0; i < notes.length; /* NOP */) {
                    const parentNote: HTMLElement = notes[i].parentId
                        ? this.notesList.querySelector("div[data-noteid='" + notes[i].parentId + "']")
                        : this.notesList;

                    if (parentNote) {
                        parentNote.appendChild(this.renderNote(notes[i]));
                        parentNote.dataset['hasreplies'] = 'true';
                        notes.splice(i, 1);
                    }
                    else {
                        // This should not happen, given that parents will
                        // always be rendered before children (replies)
                        // because of the time order.
                        i++;
                    }
                }
            }
            // Add the remaining ones, to be sure. This can only happen if notes that
            // had replies were deleted (but not the replies themselves), which we're
            // preventing in the UI.
            notes.forEach(note => this.notesList.appendChild(this.renderNote(note)));

            // The root should not have this property set to true. Only individual notes
            // can have replies. The algorithm above is indiscriminate in that respect.
            this.notesList.dataset['hasreplies'] = 'false';
        }
        catch(err) {
            this.notes = [];
            this.notesList.innerHTML = "<span class='error'>Could not load notes: " + DOMPurify.sanitize(err.message) + "</span>";

            console.error(err);
        }
        finally {
            this.notesList.scrollTop = scrollTop;
            // console.log("Reporting " + this.notes.length + " notes...");
            this.onChange(this.notes);
        };
    }


    htmlIfy(content: string): string {
        // TODO: Also process heaaders ("##"), @user references.
        content = content.trim();

        // Images: imageMatcher
        // ![bridge-1](/api/efa/v2.0/file/Test 7/bridge-1.jpg?es-tenant=DEMO-DEV)

        // Links: linkMatcher
        // [Dynatrace](https://www.dynatrace.com)

        let i = 0;
        let match: RegExpExecArray = null;
        while ((match = imageMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
            content = content.replace(match[0], `<img src="${match.groups['path']}" title="${match.groups['title']}" />`);
        }
        while ((match = linkMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
            content = content.replace(match[0], `<a target="_blank" href="${match.groups['address']}" title="${match.groups['address']}">${match.groups['title']}</a>`);
        }
        while ((match = bothMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
            content = content.replace(match[0], `<b><i>${match.groups['both']}</i></b>`);
        }
        while ((match = boldMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
            content = content.replace(match[0], `<b>${match.groups['bold']}</b>`);
        }
        while ((match = italMatcher.exec(content)) && i++ < 20) {   // Max 20 substitutions.
            content = content.replace(match[0], `<i>${match.groups['ital']}</i>`);
        }
        return content
            .replace(/\n/g, '<br>')
            .replaceAll("<br>- ", "<br>•&nbsp;&nbsp;")
            .replace(emojiMatcher, match => Emojis[match]);
    }

    renderNote(note: Note) {
        const noteRow = document.createElement("div");
        noteRow.className = "NoteRow";
        noteRow.dataset['noteid'] = '' + note.id;
        noteRow.dataset['notetype'] = '' + note.privacy;
        if (note.parentId) {
            noteRow.dataset['parentid'] = '' + note.parentId;
        }
        note.description = note.description || "(No content found)";
        noteRow.dataset['description'] = DOMPurify.sanitize(note.description.trim());

        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0);

        const email = note.authorEmail?.trim().toLowerCase();
        const user  = this.tenant.users.value.find(u => u.email?.trim().toLowerCase() == email);
        const canReply  = this.access >= 2;
        const canManage = this.access >= 16 || (
            note.authorEmail == this.email &&
            note.enteredDate.getTime() > startOfDay.getTime() &&
            !note.hasReplies
        );

        const htmlDescription = this.htmlIfy(note.description);

        noteRow.innerHTML =
            `<span class="Author">` +
                // Name contents are added later (They're real DOM elements)
                `<span class="Name"></span> ` +
                `<span class="Date">${FormatDate(note.enteredDate)}</span>` +
                `<span class="Visibility">${
                    note.privacy == 1
                    ? 'Dynatrace Only'
                    : note.authorEmail?.split('@')[1].split('.').slice(0, -1).join('.') || 'Unknown organization'
                } - ${
                    user
                    ? DOMPurify.sanitize(`${user.title}`)
                    : '(inactive)'
                }</span>` +
            `</span>` +
            `<span class="Note">` +
                `<span class="Controls">` +
                    `<button type="button" class="e-control e-btn e-lib e-flat e-dialog-edit e-warning">Delete</button>` +
                    `<span class="icon icon-reply${  canReply  ? '' : ' Hidden'  }" title="Reply">&nbsp;</span>` +
                    `<span class="icon icon-edit${   canManage ? '' : ' Hidden'  }" title="Edit">&nbsp;</span>` +
                    `<span class="icon icon-trash${  canManage ? '' : ' Hidden'  }" title="Delete">&nbsp;</span>` +
                `</span>` +
                `<span class="Bubble">${htmlDescription}</span>` +
            `</span>`;

        // Add the profile image (it _must_ be a dom element)
        const nameContainer = noteRow.querySelector('.Name');
        nameContainer.append(getUserProfileImageElement(user, ['small'], '48x48'));
        nameContainer.append(note.name || user?.name || email);

        const confirmButton = noteRow.querySelector("button");
        const replyButton = confirmButton.nextElementSibling;
        const updateButton = replyButton.nextElementSibling;
        const deleteButton = updateButton.nextElementSibling;

        confirmButton.addEventListener("click", this.deleteNote.bind(this));
        confirmButton.addEventListener("blur", this.cancelDelete.bind(this));
        noteRow.addEventListener("mouseleave ", this.cancelDelete.bind(this));
        deleteButton.addEventListener("click", this.initDelete.bind(this));
        updateButton.addEventListener("click", this.initUpdate.bind(this));
        replyButton.addEventListener("click", this.initReply.bind(this));

        return noteRow;
    }

    initUpdate(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const noteId = this.currentNote.dataset.noteid;
        const noteType = this.currentNote.dataset.notetype;
        const parentId = this.currentNote.dataset.parentid;
        const noteElem = this.currentNote.querySelector(".Note");
        const description = this.currentNote.dataset.description;
        const bubbleElem = noteElem.querySelector(".Bubble");
        const controlElem = noteElem.querySelector(".Controls");

        bubbleElem.style.display = "none";
        controlElem.style.display = "none";

        noteElem.appendChild(this.noteEditor);
        this.notesArea.classList.remove('collapsed');
        this.notesList.style.height = "100%";

        // Make sure the sendButton knows the current visibility
        // send reflects that.
        this.sendButton.setVisibility(noteType);

        // Now put in that original string!
        this.textArea.value = description;

        this.textArea.parentNode.dataset.mode = "Edit";
        this.textArea.parentNode.dataset.note = noteId;
        this.textArea.parentNode.dataset.notetype = noteType;
        this.textArea.parentNode.dataset.parent = parentId;
        this.textArea.focus();


        // So that we can apply a style that hides the date.
        // this.textArea.parentNode.parentNode.parentNode.dataset.mode = "Edit";
    }

    initReply(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const noteId = this.currentNote.dataset.noteid;
        const noteType = this.currentNote.dataset.notetype; // Parent note's privacy type ("0" or "1")
        const noteElem = this.currentNote.querySelector(".Note");
        noteElem.classList.add("Selected");
        noteElem.classList.add("Replying");

        this.currentNote.appendChild(this.noteEditor);
        this.notesArea.classList.remove('collapsed');
        this.notesList.style.height = "100%";

        // Make sure the sendButton knows the current visibility
        // send reflects that. Passing 'true' means: cannot be changed.
        this.sendButton.setVisibility(noteType, true);

        this.textArea.value = "";
        this.textArea.parentNode.dataset.mode = "Reply";
        this.textArea.parentNode.dataset.noteid = noteId;
        this.textArea.parentNode.dataset.notetype = noteType; // Set dataset for reply's privacy

        this.textArea.focus();
    }

    initDelete(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const confirmButton = this.currentNote.querySelector("button");
        confirmButton.classList.add("Visible");
        confirmButton.focus();
    }

    finishEdit(event) {
        event = event || { currentTarget: this.textArea };

        if (this.currentNote) {
            const noteElem = this.currentNote.querySelector(".Note");
            noteElem.classList.remove("Selected");
            noteElem.classList.remove("Replying");
        }
        const value = event.currentTarget.value.trim();
        if (value) {
            const noteType = event.currentTarget.parentNode.dataset.notetype;
            const mode = event.currentTarget.parentNode.dataset.mode;
            switch (mode) {
                case "New":   this.addNewNote( value, noteType); break;
                case "Edit":  this.updateNote( value, noteType); break;
                case "Reply": this.replyToNote(value, noteType); break;
            }
        }
        else {
            this.startAutoRefresh();
        }
    }

    cancelEdit(event) {
        if (this.currentNote) {
            //  const noteRows = [...this.notesArea.querySelectorAll(".NoteRow")];
            // noteRows.forEach(row => row.dataset.mode = null);

            const noteElem = this.currentNote.querySelector(".Note");
            const bubbleElem = noteElem.querySelector(".Bubble");
            const controlElem = noteElem.querySelector(".Controls");

            noteElem.classList.remove("Selected");
            noteElem.classList.remove("Replying");

            bubbleElem.style.display = "";
            controlElem.style.display = "";

            this.restoreNewNoteControl();
        }
        else {
            this.textArea.value = "";
            this.textArea.parentNode.dataset.mode = "New";
            this.textArea.parentNode.dataset.note = null;
        }

        this.startAutoRefresh();
    }

    cancelDelete(event) {
        event.currentTarget.classList.remove("Visible");
        this.startAutoRefresh();
    }

    addNewNote(value, noteType) {
        const note: PartialNote = {
            referenceId: this.assetId,
            privacy: noteType == "0" ? 0 : 1,
            description: value
        };
        if (this.access < 2)
            return window.alert("You are not allowed to add notes");

        this.notesAPI.create(this.assetId, note)
            .then(note => {
                note.enteredDate = new Date(note.enteredDate);
                note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";
                note.hasReplies = false;

                this.notes.push(note);
                this.notesList.appendChild(this.renderNote(note));
                this.restoreNewNoteControl();
                this.notesList.scrollTo(0, this.notesList.scrollHeight);
            })
            .catch(err => {
                window.alert("Could not add note: " + err);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    updateNote(value, noteType) {
        if (!this.currentNote) return;
        const noteId = parseInt(this.currentNote.dataset.noteid);
        const note = this.notes.find(note => note.id === noteId);
        if (!note) return;

        if (this.access < 2)
            return window.alert("You are not allowed to update notes");

        note.description = value;
        note.privacy = noteType == "0" ? 0 : 1;

        this.notesAPI.update(this.assetId, note)
            .then((note: Note) => {
                const noteElem = this.currentNote.querySelector(".Note");
                const bubbleElem = noteElem.querySelector(".Bubble");
                const controlElem = noteElem.querySelector(".Controls");

                bubbleElem.innerHTML = DOMPurify.sanitize(note.description); // Should be same as value now.

                bubbleElem.style.display = "";
                controlElem.style.display = "";

                this.restoreNewNoteControl();
            })
            .catch(err => {
                window.alert("Could not update note: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    replyToNote(value, noteType) {
        if (!this.currentNote) return;

        if (this.access < 2)
            return window.alert("You are not allowed to add notes");

        const note: PartialNote = {
            parentId: parseInt(this.currentNote.dataset.noteid),
            privacy: noteType == "0" ? 0 : 1,
            description: value
        };

        this.notesAPI.create(this.assetId, note)
            .then(note => {
                note.enteredDate = new Date(note.enteredDate);
                note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";

                const parentNote = this.notes.find(n => note.parentId == n.id);
                if (parentNote) parentNote.hasReplies = true;

                this.notes.push(note);

                this.currentNote.appendChild(this.renderNote(note));
                this.currentNote.dataset.hasreplies = true;

                this.restoreNewNoteControl();
            })
            .catch(err => {
                window.alert("Could not add reply: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    deleteNote(event) {
        const self = this;
        function collectDescendants(id: number): Note[] {
            const notesToDelete = self.notes.filter(n => n.parentId === id);
            return [...notesToDelete, ...notesToDelete.map(n => collectDescendants(n.id)).flat()];
        }

        if (!this.currentNote) return;
        const noteId = parseInt(this.currentNote.dataset.noteid);
        const note = this.notes.find(note => note.id === noteId);
        if (!note) return;

        if (this.access < 2)
            return window.alert("You are not allowed to delete notes");

        this.notesAPI.delete(this.assetId, note.id)
            .then(conf => {
                // NOTE: The server deletes child notes as well,
                // and so we do the same thing here.
                const deletedNotes = [note.id, ...collectDescendants(note.id)];
                this.notes = this.notes.filter(n => !deletedNotes.includes(n.id));

                const parentNote = this.notes.find(n => note.parentId == n.id);
                if (parentNote) parentNote.hasReplies = !!this.notes.find(n => n.parentId == parentNote.id);

                const parent = this.currentNote.parentNode;
                parent.removeChild(this.currentNote);

                // DEBUG
                // if (!!parent.querySelector(".NoteRow") !== parentNote.hasReplies) {
                //     console.error("Mismatch in expectations around children for note " + parentNote.id);
                // }

                parent.dataset.hasreplies = !!parent.querySelector(".NoteRow");
            })
            .catch(err => {
                window.alert("Could not remove note: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    restoreNewNoteControl() {
        if (this.currentNote) {
            const bubbleElem = this.currentNote.querySelector(".Note");
            bubbleElem.style.display = "";
        }

        this.textArea.value = "";
        this.textArea.parentNode.dataset.mode = "New";
        this.textArea.parentNode.dataset.note = null;

        this.notesArea.appendChild(this.noteEditor);
        this.notesList.style.height = null;
        this.currentNote = null;

        // Make sure the sendButton knows the current visibility
        // send reflects that.
        this.sendButton.setVisibility("0");
    }
    // TODO: Are we using this?
    toggleVisibility(event) {
        const isInteralButton = event.currentTarget;
        const noteType = isInteralButton.parentNode.dataset.notetype;
        isInteralButton.parentNode.dataset.notetype = noteType == "0" ? "1" : "0";
    }
}

const Emojis = {
    ":)": "🙂",
    ":-)": "🙂",
    ":(": "🙁",
    ":-(": "🙁",
    ":D": "😀",
    ":-D": "😀",
    "=-D": "😂",
    ";)": "😉",
    ";-)": "😉",
    ":P": "😛",
    ":-P": "😛",
    ":O": "😮",
    ":-O": "😮",
    "O:)": "😇",
    "O:-)": "😇",
    "8)": "😎",
    ":'(": "😢",
    ":')": "😅",
    "<3": "❤️",
    "=/\=": "🙏",
    ":pray:": "🙏",
  //  "/b": "👍",
    ":ty:": "👍",
    ":\\": "😕",
    ":-\\": "😕",
    ":-|": "😑",
    ":-/": "😞",
};

const escape = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")
const emojiMatcher = new RegExp(
    Object.keys(Emojis).map(escape).join("|"),
    "g"
);

const italMatcher = /\*(?<ital>(\w+))\*/;
const boldMatcher = /\*\*(?<bold>(\w+))\*\*/;
const bothMatcher = /\*\*\*(?<both>(\w+))\*\*\*/;
const imageMatcher = /!\[(?<title>(.*))\]\((?<path>(.*))\)/;
const linkMatcher  = /\[(?<title>(.*))\]\((?<address>(.*))\)/;

/*
:)	    Smiley face
:-)	    Smiley face
:]	    Content face
;)	    Winky face
;-)	    Winky face
:D	    Thrilled face
:-D	    Thrilled face
;P	    Goofy face
;-P	    Goofy face
:P	    Silly face
:-P	    Silly face
8)	    Cool guy face
8-)	    Cool guy face
:|	    Blank face
:-|	    Blank face
:(	    Sad face
:-(	    Sad face
o_O	    Grossed out face
o.O	    Grossed out face
:/	    Sick face
:-/	    Sick face
:O	    Surprised face
:-O	    Surprised face
O_O	    Tweak face
O.O	    Tweak face
o_o	    Grossed out face
#)	    Poundie
#-)	    Poundie
^.^	    Nerdie Poundie
^_^	    Nerdie Poundie
^^	    Nerdie Poundie
:x	    Kissy face
:-x	    Kissy face
<3	    Heart
#(	    Frowndie
#-(	    Frowndie
m)	    Facepalm
m-)	    Facepalm
xD	    Crying laughing
XD	    Crying laughing
:'(	    Crying face
:'-(	Crying face

^-^	Delighted; happy face

}:>    or    }:-> Devilish smile

:X    or    :-X Lips are sealed


:C    or    :-C Big frown / Grumpy

:'(    or    :'-( Crying

:-/ Sarcasm

:>    or    :-> Big grin

:p    or    :-p Sticking out tongue

:*    or    :-* Kiss

:o    or    :-o Surprise; outcry

:O    or    :-O Scream

:V    or    :-V Talking; yakking

8)    or    8-)  Smiley with glasses; author wears glasses

|:o Ooooohhh noooooo!

O_O	Shocked; surprised; bug face
O_o	Confused face
<_<	Disappointed; ashamed; upset face
-_-	Equivalent to latter
 */
